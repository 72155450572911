.App {
  text-align: center;
  font-family: 'Poppins', sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;


.App-link {
  color: #61dafb;
}

@import "~react-image-gallery/styles/css/image-gallery.css";
